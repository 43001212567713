import { useEffect, useState } from "react";
import numberToPersian from "../../utils/numberToPersian";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../../hooks/useAuthContext";
import { AxiosError } from "axios";
import PageHeader from "../../components/PageHeader";
import parse from "html-react-parser";
import {
  ScaleFade,
  Flex,
  Text,
  Card,
  CardBody,
  FormLabel,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Button,
  Skeleton,
  Tag,
  Alert,
  AlertIcon,
  Box,
} from "@chakra-ui/react";
import CustomNumberInput from "../../components/CustomNumberInput";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useDefaultToast from "../../hooks/useDefaultToast";
import { ApiResponse, Bill } from "../../types/apiTypes";

export default function PayVip() {
  const supportSlug = "financial_support";
  const { authorizedAxios, checkForInvalidToken } = useAuth();
  const showToast = useDefaultToast();
  const [searchParams] = useSearchParams();

  const stateBill = useLocation().state?.bill as Bill | undefined;

  const { subject } = useParams<{ subject: string }>();
  const navigate = useNavigate();

  const getBillQuery = useQuery({
    queryKey: ["getBillQuery"],
    staleTime: Infinity,
    cacheTime: 0,
    retry: 0,
    queryFn: async () => {
      if (stateBill) return stateBill;

      const res = await authorizedAxios.get<ApiResponse<Bill>>(
        `/bill/?subject=` + subject
      );
      const resBill = res.data.data.items[0];
      if (!resBill) {
        navigate("/");
        showToast({
          title: "پرداخت مورد نظر یافت نشد!",
          description:
            "آدرس را بررسی نمایید در صورت اطمینان از آن با پشتیبان موضوع را درمیان گذارید.",
          id: "getBillQuery_UNHANDLED_ERROR",
          status: "error",
        });
        return undefined;
      }

      return resBill;
    },
    onError: (e: AxiosError) => {
      //TODO Handle it globaly
      if (checkForInvalidToken(e)) return;

      //maybe net error
      if (e.code === "ERR_NETWORK") {
        //ERR_NETWORK error
        return showToast({
          title: "در برقراری ارتباط با سرور خطایی روی داد!",
          description: "لطفا اتصال اینترنت خود را بررسی نمایید.",
          id: "ERR_NETWORK",
          status: "error",
        });
      }

      //unhandled error
      return showToast({
        title: "در دریافت پرداخت خطایی روی داد!",
        description:
          "باعرض پوزش در دریافت پرداخت خطایی روی داد از اینکه شکیبایی می کنید متشکریم.",
        id: "getBillQuery_UNHANDLED_ERROR",
        status: "error",
      });
    },
  });

  //its ok to call useEffect every time
  const bill = getBillQuery.data || ({} as Bill);

  const [donateAmount, setDonateAmount] = useState<number>(0);

  /** Manage the request to receive the payment link */
  const getPaymentLinkMutation = useMutation({
    mutationKey: ["getPaymentLinkMutation", { slug: supportSlug }],
    retry: 0,
    mutationFn: async () => {
      if (getBillQuery.isLoading) return null;

      const hemayatBillId = bill._id;
      const min_amount = getBillQuery?.data?.min_amount;
      if (min_amount && donateAmount < min_amount) {
        showToast({
          title: "مبلغ را اصلاح کنید!",
          description: `مبلغ نمیتواند کمتر از ${min_amount.toLocaleString(
            "fa"
          )} تومان باشد `,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      /** Send a request to get the payment link */
      const getPaymentLinkRes = await authorizedAxios.post(`/pay/`, {
        amount: donateAmount,
        bill_id: hemayatBillId,
        redirection_url: "https://shamim313.com/p/r/",
      });

      return getPaymentLinkRes.data.data.payment_url;
    },
    onSuccess: (paymentUrl) => {
      if (paymentUrl) {
        showToast({
          title: "در حال انتقال به درگاه پرداخت",
          status: "success",
          duration: 4000,
          isClosable: true,
        });

        /** Open payment portal page */
        window.open(paymentUrl, "_self");
      }
    },
    onError: (e: AxiosError<any>) => {
      if (checkForInvalidToken(e)) return;

      if (e.response?.data) {
        if (e.response.data.message.includes("Zarinpal error")) {
          showToast({
            title: "خطای درگاه پرداخت",
            description: "ممکن است مبلغ وارد شده مجاز نباشد، آنرا بررسی کنید!",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        }
      } else {
        showToast({
          title: "خطایی رخ داد",
          description: "لطفا اینترنت خود را بررسی کنید.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });

        // toast({
        //   title: `کد خطا: ${e.response.status}`,
        //   description: JSON.stringify(e.response.data, null, 2),
        //   status: "error",
        //   duration: 4000,
        //   isClosable: true,
        // });
      }
    },
  });

  useEffect(() => {
    const paymentStatus = searchParams.get("ps");
    if (!paymentStatus) return;
    if (!bill?.title) return;

    if (paymentStatus) {
      if (paymentStatus === "200") {
        showToast({
          title: "پرداخت شما با موفقیت انجام شد",
          description: "موضوع پرداخت : " + bill.title,
          status: "success",
          id: "SuccessPay",
          duration: 6666,
        });
      } else {
        showToast({
          title: "متاسفانه، پرداخت با موفقیت انجام نشد!",
          description: "در صورت تمایل می توانید دوباره اقدام کنید.",
          status: "error",
          id: "ErrorPay",
          duration: 6666,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, bill]);

  useEffect(() => {
    if (
      getBillQuery.data?.amount !== undefined &&
      getBillQuery.data?.amount !== null
    ) {
      setDonateAmount(getBillQuery.data.amount);
    }
  }, [getBillQuery.data]);

  return (
    <ScaleFade initialScale={0.1} in>
      <Flex w="100%" flexDirection="column" gap={5}>
        <PageHeader
          //@ts-ignore
          title={
            <>
              {bill.title}
              {bill.vip && (
                <Tag colorScheme="green" mr={2}>
                  خانواده
                </Tag>
              )}
            </>
          }
          drawerTitle="راهنمای پرداخت"
          hasCard
        >
          <Flex flexDirection="column" alignItems="stretch" gap="1rem">
            <Text textAlign="justify">
              برای حمایت از کانون خادمان امام زمان (عج) و عضویت در خانواده شمیم
              مهدوی
            </Text>
          </Flex>
        </PageHeader>

        <Flex
          flexDirection={{ base: "column", md: "row" }}
          alignItems="flex-start"
          gap={0}
        >
          <Card w="100%" flex="1">
            <Skeleton isLoaded={!getBillQuery.isLoading} zIndex={0}>
              <CardBody zIndex={0}>
                <Box>{parse(`${getBillQuery.data?.description}`)}</Box>
                <Text
                  mt={2}
                  fontSize="sm"
                  textAlign="justify"
                  fontWeight="bolder"
                >
                  شما می‌توانید مبلغ دلخواه خود را با کلیک بر روی فیلد مبلغ وارد
                  نمایید.
                </Text>
                <FormControl isInvalid={!Boolean(donateAmount)}>
                  <FormLabel mt={6}>مبلغ (به تومان)</FormLabel>

                  <CustomNumberInput
                    defaultValue={getBillQuery.data?.amount ?? undefined}
                    step={50000}
                    min={0}
                    inputBgColor="#40e0e324"
                    //@ts-ignore
                    onChange={(value) => setDonateAmount(+value)}
                  />

                  {!isNaN(donateAmount) ? (
                    <FormHelperText
                      fontSize="md"
                      textAlign="center"
                      mt=".75rem"
                      padding=".25rem 1rem"
                      borderRadius="md"
                    >
                      {`${numberToPersian(donateAmount)} تومان`}
                    </FormHelperText>
                  ) : null}
                </FormControl>
                <Button
                  w="100%"
                  mt={3}
                  colorScheme="telegram"
                  isLoading={getPaymentLinkMutation.isLoading}
                  onClick={() => {
                    getPaymentLinkMutation.mutate();
                  }}
                  isDisabled={!donateAmount || donateAmount === 0}
                  className={
                    donateAmount || donateAmount > 0
                      ? "glowing_golden_border"
                      : ""
                  }
                >
                  پرداخت
                </Button>
                <Link
                  to={`/p/${bill.subject}/mp/`}
                  state={{ title: bill.title, id: bill._id }}
                >
                  <Button w="100%" mt={3} variant="ghost">
                    ثبت دستی فیش کارت به کارت
                  </Button>
                </Link>
              </CardBody>
            </Skeleton>
          </Card>
        </Flex>
        {/* {bill.subject === "sadagheh" && ( */}
        {getBillQuery.data?.extra_info.title && (
          <Text textAlign="center" fontWeight={700}>
            {getBillQuery.data?.extra_info.title}
          </Text>
        )}
        {getBillQuery.data?.extra_info.fields && (
          <Card mb={30}>
            <CardBody>
              <Flex gap={5} flexWrap="wrap">
                {Object.entries(getBillQuery.data?.extra_info.fields || {}).map(
                  ([key, value]) => {
                    const videoSrc = Object.values(value.values)[0];
                    console.log("value=", value);
                    return (
                      <Flex direction="column" gap={5} key={key} maxW="400px">
                        <Text fontWeight="bold" textAlign="center">
                          {value.description}
                        </Text>
                        {value.keyType === "Video" && (
                          <video
                            src={typeof videoSrc === "string" ? videoSrc : ""}
                            width="100%"
                            style={{ borderRadius: 10 }}
                            controls
                          />
                        )}
                      </Flex>
                    );
                  }
                )}
              </Flex>
            </CardBody>
          </Card>
        )}
        {/* // )}*/}
      </Flex>
    </ScaleFade>
  );
}
