import { Link, useNavigate } from "react-router-dom";
import "./index.css";
import { Box, Button, useColorMode, Text, Skeleton } from "@chakra-ui/react";
import { BANNER_API_KEY } from "../../utils/keys";
import axios, { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../../hooks/useAuthContext";
import useDefaultToast from "../../hooks/useDefaultToast";

interface BannerItem {
  destinationType: string;
  destination: string;
  dimension: string;
  placement: string;
  title: string;
  description: string;
  buttonText: string;
  buttonColor: string;
}
interface BannerData {
  page: number;
  lastPage: number;
  pageSize: number;
  sort: string;
  desc: boolean;
  total: number;
  items: BannerItem[];
}
interface BannerResponse {
  success: boolean;
  message: string;
  data: BannerData;
  datetime: string;
}

const Ad = () => {
  const colorMode = useColorMode();

  const getBannerQuery = useQuery({
    queryKey: ["getBannerQuery"],
    staleTime: Infinity,
    cacheTime: 0,
    retry: 0,
    queryFn: async () => {
      const res = await axios.get<BannerResponse>(
        "https://nbe.shamim313.com/v1/api/banner/top_bar",
        {
          headers: {
            "x-api-key": BANNER_API_KEY,
            "Content-Type": "application/json",
          },
        }
      );
      const resBanner = res?.data?.data?.items;
      return resBanner;
    },
  });
  return (
    <div
      style={{
        backgroundImage: "url(/images/islamic-ornament-persian-01.jpg)",
        width: "100%",
        backgroundSize: "cover",
        padding: "7px",
        position: "sticky",
        top: 0,
        zIndex: 9,
      }}
    >
      <Link
        to={
          getBannerQuery?.data?.length
            ? getBannerQuery.data[0]?.destination
            : "#"
        }
        target={
          getBannerQuery?.data?.[0]?.destinationType === "external"
            ? "_blank"
            : "_self"
        }
      >
        <Box
          style={{
            padding: "10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          bg={
            colorMode.colorMode === "light"
              ? "rgba(255, 255, 255, 0.9)"
              : "rgb(26 32 44 / 90%)"
          }
        >
          {getBannerQuery.isLoading ? (
            <>
              <Skeleton w="100%" h="40px" borderRadius="6px" />
            </>
          ) : (
            <>
              <div>
                <strong>
                  {/* <h1>😳 پاداشی که نه چشمی دیده نه گوشی شنیده</h1> */}
                  <Text fontSize={{ base: "0.8rem", md: "1rem" }}>
                    {/* ✨ در ثواب هدایای چله شریک شوید ✨ */}
                    {getBannerQuery?.data?.[0]?.title || ""}
                  </Text>
                  <Text fontSize={{ base: "0.8rem", md: "1rem" }}>
                    {/* چله زیارتی تحکیم خانواده - استاد فریمانه */}
                    {getBannerQuery?.data?.[0]?.description || ""}
                  </Text>
                </strong>
              </div>
              <div>
                <Button
                  variant="solid"
                  bg={getBannerQuery?.data?.[0]?.buttonColor || "red"}
                  color="white"
                  width="100%"
                  padding={{ base: "0 4px", md: "0 50px" }}
                >
                  {getBannerQuery?.data?.[0]?.buttonText || ""}
                </Button>
              </div>
            </>
          )}
        </Box>
      </Link>
    </div>
  );
};

export default Ad;
